// const LOCALE = document.querySelector('html').getAttribute('lang') ?? 'nl';

// let BASE_URL = 'https://studiekeuzetest.local'
// let PDF_URL = 'https://studiekeuzetest.local/survey/index/pdf/key'
let BASE_URL = 'https://admin.nhlstendenstudiekeuzetest.nl'
let PDF_URL = 'https://admin.nhlstendenstudiekeuzetest.nl/survey/index/pdf/key'
let LOCALE = 'nl'

if (window.location.hostname === 'find-your-degree.com') {
    // BASE_URL = 'https://studiekeuzetest.local'
    // PDF_URL = 'https://studiekeuzetest.local/survey/index/pdf/key'
    BASE_URL = 'https://admin.find-your-degree.com'
    PDF_URL = 'https://admin.find-your-degree.com/survey/index/pdf/key'
    LOCALE = 'en'
}

export { BASE_URL, PDF_URL, LOCALE }