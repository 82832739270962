import './App.css';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from '@mui/material/styles'
import { useRoutes } from 'react-router-dom';
import MuiThemeInspector from './components/MuiThemeInspector'
import Routes from './routes'
import theme from './theme'
import { AppContextProvider } from './context/AppContext'
import { AnalyticsContextProvider } from './context/AnalyticsContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useEffect } from "react"
import { LOCALE } from './config';
const queryClient = new QueryClient()

function App() {
  const content = useRoutes(Routes)

  let documentTitle = LOCALE !== 'en' ? 'Studiekeuzetest NHL/Stenden' : 'Find Your Degree Quiz NHL/Stenden'

  useEffect(() => {
    document.title = documentTitle
  }, [])
  
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <AnalyticsContextProvider>
          <AppContextProvider>
            {content}
            <Toaster
              position="top-center"
              toastOptions={{
                error: {
                  duration: 6000
                }
              }}
            />
          </AppContextProvider>
        </AnalyticsContextProvider>
      </ThemeProvider>
      { process.env.NODE_ENV === 'development' && <MuiThemeInspector /> }
    </QueryClientProvider>
  )
}

export default App;
